<template>
  <v-app>
    <app-navigation v-if="authenticated"></app-navigation>

    <v-main transition="slide-x-transition">
      <router-view />
    </v-main>

    <v-footer color="#DEDEDE" height="auto" style="margin: auto 0 0 0">
      <div v-if="!authenticated">
        <v-btn small href="https://croppaco.com/farm-simple-terms-of-use" text target="_blank" class="mr-1">Terms of Use</v-btn>|
        <v-btn small href="https://croppaco.com/farm-simple-privacy-policy" text target="_blank" class="mr-1">Privacy Policy</v-btn>
      </div>
      <v-spacer />
      <div style="margin-right: 20px">
        <span style="margin-left: 10px; color: #4f4f4f">
            &copy; {{ new Date().getFullYear() }} <strong>CroppaCo</strong>
        </span>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import AppNavigation from '@/components/AppNavigation';

export default {
  name: 'App',
  components: {
    AppNavigation
  },
  computed: {
      authenticated() {
          return this.$store.getters.isAuthenticated;
      }
  }
};
</script>
