import Vue from 'vue';
import VueRouter from 'vue-router';
import Front from '@/views/Front.vue';
import store from '@/store';

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    {
      path: '*',
      redirect: '/'
    },
    {
      path: '/',
      name: 'Login',
      component: Front
    },
    {
      path: '/home',
      name: 'Grain',
      component: () => import('@/views/Home.vue'),
      meta: {
        authRequired: true
      }
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authRequired)) {
      if (!store.getters.isAuthenticated) {
          next({
              path: '/front'
          });
      } else {
          next();
      }
  } else {
      next();
  }
});

export default router
