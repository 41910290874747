// https://vuex.vuejs.org/en/actions.html

import router from '@/router';
import { snapshotToArray, snapshotToArraySingle, snapshotToList } from '@/common';

import { firebase } from '@firebase/app';
import '@firebase/auth';
import '@firebase/database';
import '@firebase/functions';

export default {
    userLogin({ commit, dispatch }, { email, password }) {
        commit('setFirebaseWorking', true);
        firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(user => {
                commit('setUserId', user.user.uid);
                firebase
                    .database()
                    .ref('users_pub/' + user.user.uid)
                    .once('value', snapshot => {
                        commit('setSubscriberId', snapshot.val());
                        var subId = snapshot.val();
                        firebase
                            .database()
                            .ref('subscribers/' + subId + '/users/' + user.user.uid)
                            .once('value', snapshot => {
                                // Initialise contract view
                                var canViewContracts = true;
                                
                                // Not owner?
                                if (snapshot.val().permissions != 0xaa) {
                                    // Has view permission map?
                                    if (typeof snapshot.val().viewPermissionsMap == 'number') {
                                        // Get map
                                        let vpMap = snapshot.val().viewPermissionsMap;

                                        // Does not have grain view permissions?
                                        if ((vpMap & 0x01) == false) {
                                            // Alert and sign out
                                            alert("You do not have grain inventory view permissions");
                                            firebase
                                                .auth()
                                                .signOut()
                                                .then(() => {
                                                    commit('setFirebaseWorking', false);
                                                    commit('setUserId', null);
                                                    commit('setSubscriberId', null);
                                                })
                                            return;
                                        }

                                        // Does not have contract view permissions?
                                        if ((vpMap & 0x02) == false) {
                                            canViewContracts = false;
                                        }
                                    }
                                }

                                commit('setUserDetails', {
                                    key: user.user.uid,
                                    firstName: snapshot.val().firstName,
                                    lastName: snapshot.val().lastName,
                                    email: snapshot.val().email,
                                    archived: snapshot.val().archived,
                                    permissions: snapshot.val().permissions,
                                    permissionsMap:
                                        typeof snapshot.val().permissionsMap == 'number'
                                            ? snapshot.val().permissionsMap
                                            : 0x00,
                                    ticketCount:
                                        typeof snapshot.val().ticketCount == 'number'
                                            ? snapshot.val().ticketCount
                                            : 0,
                                    canViewContracts: canViewContracts
                                });

                                firebase
                                    .database()
                                    .ref('subscribers/' + subId + '/general')
                                    .once('value', snapshot => {
                                        commit('setGeneral', snapshot.val());

                                        firebase
                                            .database()
                                            .ref('subscribers/' + subId + '/subscription')
                                            .once('value', snapshot => {
                                                commit('setSubscription', snapshot.val());
                                                dispatch('getUsersSubs');
                                                dispatch('getTickets');
                                                dispatch('getContracts');
                                                dispatch('getUsers');
                                                dispatch('getCultivars');
                                                dispatch('getFarms');
                                                dispatch('getGrainSettings');
                                                commit('setFirebaseWorking', false);
                                                router.push('/home');
                                            });
                                    });
                            });
                    });
            })
            .catch(err => {
                dispatch('clearData');
                alert(err.message);
            });
    },
    clearData({ commit }) {
        commit('setFirebaseWorking', false);
        commit('setUserId', null);
        commit('setSubscriberId', null);
        commit('setSubscription', null);
        commit('setUsersSubs', null);
        commit('setGeneral', null);
        commit('setGrainSettings', null);
        commit('setUserDetails', null);

        /* Assets */
        commit('setFarms', null);
        commit('setCultivars', null);
        commit('setUsers', null);

        /* Inventory */
        commit('setTickets', null);
        commit('setContracts', null);
    },
    userSignOut({ commit, dispatch }) {
        commit('setFirebaseWorking', true);
        firebase
            .auth()
            .signOut()
            .then(() => {
                dispatch('clearData');
                router.push('/');
            })
            .catch(() => {
                dispatch('clearData');
                router.push('/');
            });
    },
    getUsersSubs({ state, commit }) {
        firebase
            .database()
            .ref('users_subs/' + state.userId)
            .once('value', snapshot => {
                commit('setUsersSubs', snapshotToList(snapshot.val()));
            });
    },
    switchUserSub({ commit, dispatch }, { subToChangeTo }) {
        commit('setFirebaseWorking', true);

        // Create Function call
        var addMessage = firebase.functions().httpsCallable('switchSub');

        // Call switch sub
        addMessage({ subscriber_id: subToChangeTo })
            .then(result => {
                console.log(result);
                dispatch('userSignOut');
                commit('setFirebaseWorking', false);
            })
            .catch(err => {
                alert(err.message);
                commit('setFirebaseWorking', false);
            });
    },
    getGrainSettings({ state, commit }) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/grainSettings')
            .once('value', snapshot => {
                commit('setGrainSettings', snapshot.val());
            });
    },
    getTickets({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/tickets')
            .once('value', snapshot => {
                commit('setTickets', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    addTicket({ state, commit }, payload) {
        // Create blank item and get generated key
        let newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/tickets')
            .push().key;
        
        var userId = '';
        var ticketCount = 0;

        //  Creation ID Present?
        if (payload.creationId) {
            // Add key to middle
            payload.creationId = payload.userId.slice(-3) + '-' + newKey.slice(-3) + '-' + payload.creationId;
        }

        if (payload.ticketCount) {
            ticketCount = payload.ticketCount;
            delete payload.ticketCount;
        }

        if (payload.userId) {
            userId = payload.userId;
            delete payload.userId;
        }

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/tickets')
            .child(newKey)
            .update(payload, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload['key'] = newKey;
                    commit('addTicket', payload);

                    if (userId != '' && ticketCount != 0) {
                        // Update user ticket count
                        firebase
                            .database()
                            .ref('subscribers/' + state.subscriberId + '/users')
                            .child(userId)
                            .update({'ticketCount': ticketCount}, error => {
                                // Callback for error or success
                                if (error) {
                                    alert(error.message);
                                } else { 
                                    commit('updateUserTicketCount', ticketCount);
                                    alert('Ticket Added');
                                }
                            });
                    } else {
                        alert('Ticket Added');
                    }
                }
            });
    },
    updateTicket({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/tickets')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('updateTicket', {
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    getContracts({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/contracts')
            .once('value', snapshot => {
                commit('setContracts', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    addContract({ state, commit }, payload) {
        // Create blank item and get generated key
        let newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/contracts')
            .push().key;

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/contracts')
            .child(newKey)
            .update(payload, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload['key'] = newKey;
                    commit('addContract', payload);
                    alert('Contract Added');
                }
            });
    },
    updateContract({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/contracts')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('updateContract', {
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    getFarms({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/farms')
            .once('value', snapshot => {
                var tempFarmList = snapshotToArraySingle(snapshot.val());
                var paddockArray = [];
                var silosArray = [];

                for (let index in tempFarmList) {
                    paddockArray.push(snapshotToArray(snapshot.child(tempFarmList[index].key).child('paddocks').val()));
                    silosArray.push(snapshotToArray(snapshot.child(tempFarmList[index].key).child('silos').val()));
                }

                commit('setFarms', {
                    farmList: tempFarmList,
                    paddocks: paddockArray,
                    silos: silosArray
                });
                commit('setFirebaseWorking', false);
            });
    },
    updateSiloCultivar({ state, commit }, payload) {
        /* Payload: farm
         *          key
         *          index
         *          item
         */

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/farms')
            .child(state.farms.farmList[payload.farm].key)
            .child('silos')
            .child(payload.key)
            .update({cultivarId: payload.item}, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    commit('updateSiloCultivar', {
                        farm: payload.farm,
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    getCultivars({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/cultivars')
            .once('value', snapshot => {
                commit('setCultivars', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    getUsers({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/users')
            .once('value', snapshot => {
                commit('setUsers', {
                    userList: snapshotToArraySingle(snapshot.val()),
                    userDetails: snapshotToArray(snapshot.val())
                });
                commit('setFirebaseWorking', false);
            });
    }
};
