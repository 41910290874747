// https://vuex.vuejs.org/en/state.html

export default {
    activePage: 'Overview',
    userId: null,
    subscriberId: null,
    subscription: null,
    userDetails: null,
    firebaseWorking: false,
    loginBusy: false,
    usersSubs: null,
    general: null,
    grainSettings: null,
    tickets: [],
    contracts: [],
    farms: [],
    cultivars: [],
    users: []
};
