<!--
  //  Front.vue
  //  croppa-web
  //
  //  Created by Matthew Higham on 25/09/2020
  //  Copyright © 2020 CroppaCo Pty. Ltd. All rights reserved.
  -->

<template>
  <span>
    <login />
  </span>
</template>

<script>
import Login from '@/components/Login.vue'

export default {
  name: 'FrontPage',
  components: {
    Login
  }
}
</script>
