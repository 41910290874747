// https://vuex.vuejs.org/en/mutations.html

export default {
    setUserId(state, payload) {
        state.userId = payload;
    },
    setSubscriberId(state, payload) {
        state.subscriberId = payload;
    },
    setSubscription(state, payload) {
        state.subscription = payload;
    },
    setUsersSubs(state, payload) {
        state.usersSubs = payload;
    },
    setGeneral(state, payload) {
        state.general = payload;
    },
    setGrainSettings(state, payload) {
        state.grainSettings = payload;
    },
    setUserDetails(state, payload) {
        state.userDetails = payload;
    },
    setFirebaseWorking(state, payload) {
        state.firebaseWorking = payload;
    },
    setLoginBusy(state, payload) {
        state.loginBusy = payload;
    },
    setActivePage(state, payload) {
        state.activePage = payload;
    },
    setTickets(state, payload) {
        state.tickets = payload;
    },
    addTicket(state, payload) {
        state.tickets.push(payload);
    },
    updateTicket(state, payload) {
        state.tickets.splice(payload.index, 1, payload.item);
    },
    setContracts(state, payload) {
        state.contracts = payload;
    },
    addContract(state, payload) {
        state.contracts.push(payload);
    },
    updateContract(state, payload) {
        state.contracts.splice(payload.index, 1, payload.item);
    },
    setFarms(state, payload) {
        state.farms = payload;
    },
    updateSiloCultivar(state, payload) {
        state.farms.silos[payload.farm][payload.index].cultivarId = payload.item;
    },
    setCultivars(state, payload) {
        state.cultivars = payload;
    },
    setUsers(state, payload) {
        state.users = payload;
    },
    updateUserTicketCount(state, payload) {
        state.userDetails.ticketCount = payload;
    }
};
