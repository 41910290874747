//
//  common.js
//  croppa-web
//
//  Created by Matthew Higham on 25/09/2020
//  Copyright © 2020 CroppaCo Pty. Ltd. All rights reserved.
//

export function snapshotToArray(snapshot) {
    var returnArr = [];

    for (let index in snapshot) {
        var item = snapshot[index];
        item.key = index;

        returnArr.push(item);
    }

    return returnArr;
}

export function snapshotToArraySingle(snapshot) {
    var returnArr = [];

    for (let index in snapshot) {
        if (snapshot[index].name) {
            returnArr.push({
                key: index,
                name: snapshot[index].name,
                index: returnArr.length,
                archived: snapshot[index].archived ? true : false
            });
        } else if (snapshot[index].firstName) {
            returnArr.push({
                key: index,
                name: snapshot[index].firstName + ' ' + snapshot[index].lastName,
                index: returnArr.length,
                archived: snapshot[index].archived ? true : false
            });
        } else {
            returnArr.push({
                key: index,
                index: returnArr.length
            });
        }
    }

    return returnArr;
}

export function snapshotToList(snapshot) {
    var returnArr = [];

    for (let index in snapshot) {
        let item = {key: index, name: snapshot[index]};
        returnArr.push(item);
    }

    return returnArr;
}

export function floatToString2DecPl(value) {
    // Round and remove superfluous 0's
    if (value) {
        var newValue = Math.round((value + Number.EPSILON) * 100) / 100;
        return newValue.toString();
    }
    return '0';
}

export function float2DecPl(value) {
    return Math.round((value + Number.EPSILON) * 100) / 100;
}

export function buildTicketIdentifier(type, number, prefix) {
    // Valid number?
    if (number && number > 0) {
        let IdTypes = ['GRI-', 'GRO-', 'GRS-', 'GRT-'];
        if (number > 9999) {
            return prefix + IdTypes[type] + `${number}`;
        }
        return prefix + IdTypes[type] + `0000${number}`.slice(-4);
    }
    return '';
}
