<!--
  //  AppNavigation.vue
  //  croppa-web
  //
  //  Created by Matthew Higham on 25/09/2020
  //  Copyright © 2020 CroppaCo Pty. Ltd. All rights reserved.
  -->

<template>
    <span>
        <v-app-bar min-height="58px" app color="#DEDEDE" clipped-left>
            <img src="@/assets/icon-fs-orange.svg" height="50px" class="mr-5" />
            <v-toolbar-title style="color: #4f4f4f"><b>FARM</b>SIMPLE Grain</v-toolbar-title>
            <v-spacer />
            <span id="userDetails">{{ userDetails.firstName }} {{ userDetails.lastName }} </span>
            |
            <span v-bind:style="{ color: userPermission.colour }" id="userDetails"> {{ userPermission.text }}</span>
            <v-btn @click="logout" color="deep-orange darken-2" class="white--text">Logout</v-btn>
        </v-app-bar>

        <v-navigation-drawer
            width="250"
            class="blue-grey darken-4"
            dark
            permanent
            app
            clipped
            mini-variant
            v-model="drawer"
        >
            <v-list dense style="padding: 0 0 0 0">
                <v-list-item v-for="(item, index) in navItems" :key="index" @click="itemClicked(item.title)">
                    <v-list-item-action>
                        <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" width="25" height="25">{{ item.icon }}</v-icon>
                            </template>
                            <span>{{ item.title }}</span>
                        </v-tooltip>
                    </v-list-item-action>

                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </span>
</template>

<script>
import { mapState } from 'vuex';
import { mdiChartAreaspline, mdiDatabase, mdiFileDocumentMultiple, mdiNotePlus, mdiSwapHorizontalBold } from '@mdi/js';

export default {
    name: 'AppNavigation',
    data() {
        return {
            drawer: false
        }
    },
    computed: {
        ...mapState(['general', 'userDetails']),
        userPermission() {
            // Set Permissions Label
            if (this.userDetails.permissions == 0xaa) {
                return { text: 'Owner', colour: '#F44336' };
            } else if (this.userDetails.permissions == 0xa5) {
                return { text: 'Admin', colour: '#FF9800' };
            } else {
                return { text: 'User', colour: '#2196F3' };
            }
        },
        navItems() {
            if (this.userDetails.canViewContracts) {
                return [
                    { title: 'Dashboard', icon: mdiChartAreaspline},
                    { title: 'Overview', icon: mdiDatabase },
                    { title: 'Entry', icon: mdiNotePlus },
                    { title: 'Contracts', icon: mdiFileDocumentMultiple },
                    { title: 'Switch Sub', icon: mdiSwapHorizontalBold }
                ];
            }
            return [
                { title: 'Dashboard', icon: mdiChartAreaspline},
                { title: 'Overview', icon: mdiDatabase },
                { title: 'Entry', icon: mdiNotePlus },
                { title: 'Switch Sub', icon: mdiSwapHorizontalBold }
            ];
        }
    },
    methods: {
        logout() {
            this.$store.dispatch('userSignOut')
        },
        itemClicked(item) {
            this.$store.commit('setActivePage', item);
        }
    }
};
</script>

<style scoped>
a {
    color: white;
    text-decoration: none;
}
span {
    vertical-align: middle;
    margin-right: 10px;
}
#userDetails {
    line-height: normal;
    vertical-align: baseline;
    color: '#4F4F4F';
    margin-left: 5px;
    margin-right: 5px;
    font-weight: bold;
}
</style>
