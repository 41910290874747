//
//  index.js
//  croppa-web
//
//  Created by Matthew Higham on 11/08/2019
//  Copyright © 2019 CroppaCo Pty. Ltd. All rights reserved.
//

// Firebase configuration
import firebase from '@firebase/app';
import "firebase/storage";

// Configuration and initialisation
export const firebaseConfig = {
    apiKey: "AIzaSyA-7bxJD5kJOH98BiXry4ZLRxxmXxs4cdo",
    authDomain: "croppa.firebaseapp.com",
    databaseURL: "https://croppa.firebaseio.com",
    projectId: "project-1789770584308915398",
    storageBucket: "project-1789770584308915398.appspot.com",
    messagingSenderId: "838773307942",
    appId: "1:838773307942:web:3db405ebe804b33f2c84e7",
    measurementId: "G-7Q3K1S9YZ7"
};
firebase.initializeApp(firebaseConfig);
//firebase.analytics();

export const fbStorage = firebase.storage();
