<!--
  //  Login.vue
  //  croppa-web
  //
  //  Created by Matthew Higham on 25/09/2020
  //  Copyright © 2020 CroppaCo Pty. Ltd. All rights reserved.
  -->

<template>
    <v-container fluid fill-height class="login" style="max-height: 100vh">
        <v-row justify="center" align="center" pa-5>
            <v-card width="500px" class="mx-a pb-6" raised style="background-color: #dedede">
                <v-card-title class="justify-center">
                    <v-container fill-height fluid>
                        <v-row no-gutters align="center" justify="center">
                            <img style="margin-bottom: 20px" width="400px" src="@/assets/logo-colour.svg" />
                        </v-row>
                    </v-container>
                </v-card-title>
                <v-card-text>
                    <v-form>
                        <v-text-field
                            label="Email"
                            v-model="email"
                            :append-icon="mdiEmail"
                            solo
                            color="#E05317"
                            background-color="#E9E9E9"
                            @keyup.enter="$refs.pwd.focus"
                            autocomplete="username"
                            required
                            style="margin: 0px 20px"
                        />
                        <v-text-field
                            ref="pwd"
                            name="password"
                            label="Password"
                            v-model="password"
                            :append-icon="showPwd ? mdiEyeOff : mdiEye"
                            :type="showPwd ? 'text' : 'password'"
                            solo
                            color="#E05317"
                            background-color="#E9E9E9"
                            @click:append="showPwd = !showPwd"
                            @keyup.enter="login"
                            autocomplete="current-password"
                            required
                            style="margin: 0px 20px"
                        />
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn class="mx-auto" @click="login" :loading="isRunning" dark min-width="180" color="#E05317"
                        >Login</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-row>
        <v-btn class="mx-auto" absolute top right href="https://farmsimple.croppaco.com" target="_blank" dark min-width="180" color="#E05317" pt-5>
            Web Console
        </v-btn>
    </v-container>
</template>

<script>
import { mdiEmail, mdiEye, mdiEyeOff } from '@mdi/js';

export default {
    name: 'LoginPage',
    data: function () {
        return {
            mdiEmail,
            mdiEye,
            mdiEyeOff,
            email: '',
            password: '',
            showPwd: false
        };
    },
    computed: {
        isRunning() {
            return this.$store.state.firebaseWorking;
        }
    },
    methods: {
        login: function () {
            if (this.isRunning == false) {
                this.$store.dispatch('userLogin', {
                    email: this.email,
                    password: this.password
                });
            }
        }
    }
};
</script>

<style scoped>
.login {
    background-color: darkolivegreen;
    background-size: cover;
    width: 100%;
    height: 100%;
}
</style>
