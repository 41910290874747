// https://vuex.vuejs.org/en/getters.html
import { getUnixTime } from 'date-fns';

export default {
    isAuthenticated(state) {
        return (
            state.userId !== null &&
            state.userId !== undefined &&
            state.subscription !== null &&
            state.subscription !== undefined
        );
    },
    isSuperUser(state) {
        return state.userDetails.permissions == 0xa5 || state.userDetails.permissions == 0xaa;
    },
    hasContractPermissions(state) {
        return state.userDetails.permissions == 0xaa || state.userDetails.permissionsMap & 0x08;
    },
    hasPaymentPermissions(state) {
        return state.userDetails.permissions == 0xaa || 
            ((state.userDetails.permissionsMap & 0x08) && state.userDetails.canViewContracts);
    },
    areCroppingFeaturesUnlocked(state) {
        return (
            (state.subscription.productId == 'com.croppaco.farmSimple.CroppingMonthly' ||
                state.subscription.productId == 'com.croppaco.farmSimple.StandardSub' ||
                state.subscription.productId == 'com.croppaco.farmSimple.StandardAndHrSub' ||
                state.subscription.productId == 'com.croppaco.farmSimple.EnterpriseSub' ||
                state.subscription.productId == 'com.croppaco.farmSimple.EnterpriseAndHrSub') &&
                state.subscription.expiry > getUnixTime(new Date())
        );
    },
    areEnterpriseFeaturesUnlocked(state) {
        return (
            (state.subscription.productId == 'com.croppaco.farmSimple.EnterpriseSub' ||
                state.subscription.productId == 'com.croppaco.farmSimple.EnterpriseAndHrSub') &&
                state.subscription.expiry > getUnixTime(Date())
        );
    },
    isPertenImportEnabled(state) {
        return state.subscription.pertenImport == true;
    }
};
